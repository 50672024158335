import React from "react"
import Slider from "react-slick"
import "./Carousel.scss"
import Skeleton from "react-loading-skeleton"
import { imageUrl } from "../../helpers/ImageUrl"

function ImageCarousel(props) {
  const { images } = props

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
  }

  return (
    <div className="carousel image-carousel">
      {images.length ? (
        <Slider {...settings}>
          {images.map((item, index) => {
            return (
              <div key={index}>
                <div className="slide" key={index}>
                  <div className="slide__visual">
                    <img src={imageUrl(item, 795, 520)} alt="" />
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      ) : (
        <div className="slide__visual">
          <Skeleton width={"100%"} height={"100%"} />
        </div>
      )}
    </div>
  )
}

export default ImageCarousel
