import React from "react"
import "./Title.scss"
import PropTypes from "prop-types"

function Title({ children, centered, light, secondary }) {
  return secondary ? (
    <h2
      className={`title--main${centered ? " center" : ""}${
        light ? " light" : ""
      }`}
    >
      {children}
    </h2>
  ) : (
    <h1
      className={`title--main${centered ? " center" : ""}${
        light ? " light" : ""
      }`}
    >
      {children}
    </h1>
  )
}

Title.propTypes = {
  children: PropTypes.string,
}

export default Title
