import React from "react"
import ImageCarousel from "../components/Carousel/ImageCarousel"
import BodyContent from "../components/Text/BodyContent"
import Media from "../components/Media/Media"
import { getAsset } from "./Publications"
import ButtonRounded from "../components/Buttons/BtnRounded"

export function returnLayoutSection(item, index) {
  if (item.label === "Text" && !item.format) {
    return <BodyContent key={index} html={item.body} />
  } else if (item.label === "Text" && item.format) {
    if (item.format === "H1") {
      return (
        <h2 className="h1" key={index}>
          {item.body}
        </h2>
      )
    } else if (item.format === "H2") {
      return <h2 key={index}>{item.body}</h2>
    } else if (item.format === "H3") {
      return <h3 key={index}>{item.body}</h3>
    } else if (item.format === "H4") {
      return <h4 key={index}>{item.body}</h4>
    } else if (item.format === "H5") {
      return <h5 key={index}>{item.body}</h5>
    } else if (item.format === "H6") {
      return <h6 key={index}>{item.body}</h6>
    }
  } else if (item.label === "Asset") {
    const photoCollection = []
    const documentCollection = []
    item.items.map(item => {
      if (item.label === "Document") {
        documentCollection.push(item)
      } else if (item.label === "Photo") {
        const url = getAsset(item.cdn_files)
        photoCollection.push(url)
      }
    })

    if (documentCollection.length > 0 && photoCollection.length === 0) {
      return documentCollection.map(item => {
        const url = getAsset(item.cdn_files)
        return (
          <ButtonRounded
            key={index}
            onClick={() => window.open(url, "_blank")}
            large
            primary
            icon={<i className="icon-download" />}
          >{`${item.name}`}</ButtonRounded>
        )
      })
    } else if (photoCollection.length > 0 && documentCollection.length === 0) {
      return <ImageCarousel key={index} images={photoCollection} />
    }
  } else if (item.label === "YouTubePost") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "SpotifyPlaylist") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "TwitterPost") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "InstagramPost") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "SoundCloudPost") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "TikTokPost") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "VimeoPost") {
    return <Media type={item.label} key={index} src={item.url} />
  } else if (item.label === "FacebookPost") {
    return <Media type={item.label} key={index} src={item.url} />
  }
}

export function formatOEmbdedUrl(type, src) {
  if (type === "YouTubePost") {
    return `https://www.youtube.com/oembed?url=${src}&format=json`
  } else if (type === "SpotifyPlaylist") {
    return `https://open.spotify.com/oembed/?url=${src}`
  } else if (type === "TwitterPost") {
    return `https://publish.twitter.com/oembed?url=${src}`
  } else if (type === "InstagramPost") {
    return `https://graph.facebook.com/v10.0/instagram_oembed?url=${src}`
  } else if (type === "SoundCloudPost") {
    return `https://soundcloud.com/oembed?url=${src}&iframe=true`
  } else if (type === "TikTokPost") {
    return `https://www.tiktok.com/oembed?url=${src}&format=json`
  } else if (type === "VimeoPost") {
    return ` https://vimeo.com/api/oembed.json?url=${src}&format=json`
  } else if (type === "FacebookPost") {
    return `https://graph.facebook.com/v10.0/oembed_post?url=${src}`
  }
}
