import cx from 'classnames'
import PropTypes from "prop-types"
import React from "react"
import Skeleton from "react-loading-skeleton"
import { logo } from "../../constants/logo"
import { imageUrl } from "../../helpers/ImageUrl"
import { getImageType } from "../../helpers/Prepr"
import {
  getColor,
  getLabel,
  getSingleImage,
  getSingleImageName,
} from "../../helpers/Publications"
import Logo from "../Logo/Logo"
import "./MainContent.scss"

function MainContent(props) {
  const {
    headerComponent,
    image,
    imageInternal,
    image_website_block,
    image_website_carousel,
    image_website_detail,
    children,
    label,
    color,
    isCompact,
    _className,
    imageType,
    headerWidth,
    headerHeight
  } = props
  const contentClass = _className ? _className : ""

  return (
    <div
      className={`main-content container ${contentClass}${
        isCompact ? " compact" : ""
      }`}
      style={
        color
          ? {
              borderTopColor: getColor(color),
              borderTopWidth: "9px",
              borderTopStyle: "solid",
            }
          : null
      }
    >
      <div className={cx(`main-content__visual`, {
        'main-content__visual--custom': !headerComponent
      })}>
        {label && (
          <div
            className="main-content__visual--label"
            style={color ? { backgroundColor: getColor(color) } : null}
          >
            <Logo color={logo.neutral} isCompact className="label--logo" />
            <span>{getLabel(label)}</span>
          </div>
        )}
        {headerComponent ? (
          headerComponent
        ) : imageInternal ? (
          <img src={imageInternal} alt={getSingleImageName(image)} />
        ) : (
          <img
            src={imageUrl(
              getSingleImage(
                getImageType(
                  imageType,
                  image,
                  image_website_carousel,
                  image_website_block,
                  image_website_detail
                )
              ),
              headerWidth ?? 1200,
               headerHeight ?? 560
            )}
            alt={getSingleImageName(image)}
          />
        )}
      </div>
      <div className="main-content__inner">
        <div className="main-content__inner--content">{children}</div>
      </div>
    </div>
  )
}

export function MainContentLoading({ isCompact, _className }) {
  const contentClass = _className ? _className : ""

  return (
    <div
      className={`main-content container ${contentClass}${
        isCompact ? " compact" : ""
      }`}
    >
      <div className={`main-content__visual`}>
        <Skeleton width={"100%"} height={"100%"} />
      </div>
      <div className="main-content__inner">
        <div className="main-content__inner--content">
          <Skeleton width={"100%"} height={60} count={2} />
          <br />
          <br />
          <br />
          <Skeleton width={"100%"} height={20} count={15} />
        </div>
      </div>
    </div>
  )
}

MainContent.propTypes = {
  headerComponent: PropTypes.any,
  image: PropTypes.any,
  imageInternal: PropTypes.any,
  label: PropTypes.any,
  f93822: PropTypes.string,
  _className: PropTypes.string,
  isCompact: PropTypes.bool,
}

export default MainContent
