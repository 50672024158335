import React from "react"
import { Router } from "@reach/router"
import FaqOverview from "../components/Faq/Overview"

function Faq() {
  return (
    <Router>
      <FaqOverview path="veelgestelde-vragen" />
    </Router>
  )
}

export default Faq
