import { get } from "axios"
import React, { useEffect, useState } from "react"
import { useGuestApi } from "../../hooks/useApi"
import { getRadioTracksEndpoint } from "../../services/api"
import ChannelsCarousel from "../Carousel/ChannelsCarousel"
import Icon from "../Icon/Icon"
import "./PlayerSmall.scss"

function PlayerSmall() {
  const [radio] = useState(9)
  const { currentTrack, setTrack } = useGuestApi()

  useEffect(() => {
    async function fetchTracks(radioId) {
      try {
        await get(getRadioTracksEndpoint(radioId)).then(response => {
          setTrack(response.data.data[0])
        })
      } catch(e) {
        setTrack(null)
      }
    }
    fetchTracks(radio)
  }, [])

  useEffect(() => {
    async function fetchTracks(radioId) {
      try {
        await get(getRadioTracksEndpoint(radioId)).then(response => {
          setTrack(response.data.data[0])
        })
      } catch(e) {
        setTrack(null)
      }
    }
    const interval = setInterval(() => {
      fetchTracks(radio)
    }, 60000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPlayer = () => {
    const url = "/luister/playnostalgie";
    window.open(url, "_blank", "width=420,height=662")
  }

  return (
    <div className="player-small">
      <div className="player-small__inner">
        <div className="player-small__current">
          <button
            onClick={openPlayer}
            className="player-small__current--inner"
          >
            <div className="player-small__current--visual">
              <img src={currentTrack ? currentTrack.cover_art : ""} alt="" />
            </div>
            <div className="player-small__current--track">
              <h3 className="name">{currentTrack ? currentTrack.title : ""}</h3>
              <h4 className="artist">
                {currentTrack ? currentTrack.artist : ""}
              </h4>
            </div>
            <div className="player-small__current--btn">
              <span className="btn--play">
                <Icon name="play" />
              </span>
            </div>
          </button>
        </div>

        <div className="player-small__stations">
          <ChannelsCarousel isCompact />
        </div>
      </div>
    </div>
  )
}

export default PlayerSmall
