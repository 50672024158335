import React, { useState } from "react"
import "./FaqItem.scss"
import BodyContent from "../Text/BodyContent"
import Icon from "../Icon/Icon"
import { getDescription, getTitle } from "../../helpers/Publications"

function FaqItem(props) {
  const { title, description } = props
  const [faqActive, setFaqActive] = useState(false)

  const toggleFaq = () => setFaqActive(!faqActive)

  return (
    <div className={`faq__item ${faqActive ? "active" : ""}`}>
      <button onClick={toggleFaq} className={`faq__title`}>
        {getTitle(title)}
        <span className="faq__title--icon">
          <Icon name="chevron-right" />
        </span>
      </button>
      {faqActive && (
        <div className="faq__content">
          <BodyContent html={getDescription(description)} />
        </div>
      )}
    </div>
  )
}

export default FaqItem
