import React, { useState } from "react"
import ContainerLayout from "src/components/Layout/Container"

export default function withGuestLayout(Component, layoutProps) {
  return function WrapperComponent(props) {
    return (
      <ContainerLayout {...layoutProps}>
        <Component {...props} />
      </ContainerLayout>
    )
  }
}
