import React, { useEffect, useState } from "react"
import MainContent from "../../components/Layout/MainContent"
import Title from "../../components/Text/Title"
import FaqGrid from "../Grid/FaqGrid"
import "./FaqItem.scss"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import { getTitle } from "../../helpers/Publications"
import BodyContent from "../Text/BodyContent"
import { returnLayoutSection } from "../../helpers/Layout"

function FaqOverview() {
  const { getFaqsPublication } = useGuestApi();
  const [content, setContent] = useState(null);

  useEffect(() => {
    getFaqsPublication().then(response => {
      setContent(response);
    })
  }, [getFaqsPublication])

  if(!content) {
    return null;
  }

  return (
    <MainContent headerWidth={960} headerHeight={312} {...content}>
      <Title>{getTitle(content?.title)}</Title>
      <BodyContent>
        {content.layout
          ? Array.isArray(content.layout.items)
            ? content.layout.items.map((item, i) => {
                return returnLayoutSection(item, i)
              })
            : null
          : null
        }
      </BodyContent>
      <FaqGrid />
    </MainContent>
  )
}

export default withGuestLayout(FaqOverview, {
  page: "Veelgestelde vragen",
})
