import React from "react"
import "./BtnRounded.scss"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Loader from "../Loader/Loader"
import classNames from "classnames"

function BtnRounded(props) {
  const {
    children,
    icon,
    outline,
    route,
    primary,
    secondary,
    third,
    fourth,
    fifth,
    large,
    error,
    className,
    onClick,
    loading,
    modLink = false,
    href,
  } = props

  const classes = classNames("btn btn--rounded", className, {
    "btn--icon": !children,
    "btn--outline": outline,
    "btn--primary": primary,
    "btn--secondary": secondary,
    "btn--third": third,
    "btn--fourth": fourth,
    "btn--fifth": fifth,
    "btn--large": large,
    "btn--error": error,
  })

  function handleClick() {
    if (route) {
      navigate(route)
      return
    } else if (onClick) {
      onClick()
      return
    } else {
      return
    }
  }

  const TagName = modLink ? "a" : "button"

  return (
    <TagName
      type={!modLink && "button"}
      onClick={handleClick}
      className={classes}
      href={href}
    >
      {icon && <span className="icon">{icon}</span>}
      {children && (
        <span className="content">
          {loading ? (
            <Loader color={secondary ? "#FFA41F" : "#fff"} />
          ) : (
            children
          )}
        </span>
      )}
    </TagName>
  )
}

BtnRounded.propTypes = {
  icon: PropTypes.object,
  outline: PropTypes.bool,
  route: PropTypes.string,
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  third: PropTypes.bool,
  large: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
}

export default BtnRounded
