import React from "react"
import PropTypes from "prop-types"

import "./BodyContent.scss"

function BodyContent(props) {
  return (
    <>
      {props.html ? (
        <div
          className="body-content"
          dangerouslySetInnerHTML={{ __html: props.html }}
        ></div>
      ) : (
        <div className="body-content">{props.children}</div>
      )}
    </>
  )
}

BodyContent.propTypes = {
  html: PropTypes.string,
}

export default BodyContent
