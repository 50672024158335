import React, { useEffect, useState } from "react"
import { get } from "axios"
import { formatOEmbdedUrl } from "src/helpers/Layout"
import InnerHTML from "dangerously-set-html-content"
import "./media.scss"

function Media(props) {
  const { src, type } = props
  const [oEmbed, setOEmbed] = useState(null)
  const [iframe, setIframe] = useState(null)
  const [isSoundCloud, setIsSoundCloud] = useState(false)

  useEffect(() => {
    async function fetchOembed(src, type) {
      if (type !== "SoundCloudPost") {
        await get(
          `${process.env.API_BASE_URL}api/v1/oembed?url=${formatOEmbdedUrl(
            type,
            src
          )}&radio=nostalgie`
        ).then(response => {
          setOEmbed(response.data.html)
        })
      } else {
        setIsSoundCloud(true)
        await get(
          `https://soundcloud.com/oembed?iframe=true&url=${src}&format=json`
        ).then(response => {
          setOEmbed(response.data.html)
        })
      }
    }

    fetchOembed(src, type)
  }, [type])

  return (
    <div className="media-embed">
      <div
        className={
          "media-embed--" +
          (type ?? "unknown").toLowerCase() +
          (isSoundCloud ? " soundcloud-embed" : "")
        }
      >
        {oEmbed && <InnerHTML html={oEmbed} />}
      </div>
    </div>
  )
}

export default Media
