import React, { useState, useEffect } from "react"
import { useGuestApi } from "../../hooks/useApi"
import FaqItem from "../Faq/FaqItem"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 10,
}

export default function FaqGrid({ history }) {
  const [pagination, setPagination] = useState(initialPagination)
  const [page, setPage] = useState(1)
  const { getFaqPublications } = useGuestApi()
  const [faq, setFaq] = useState([])
  useEffect(fetchFaqPublications, [page])

  function fetchFaqPublications() {
    getFaqPublications(pagination).then(response => {
      setPagination({
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      })
      setFaq(faq.concat(response.grid.items))
    })
  }

  return (
    <div className="faq__grid">
      {faq.map((faqItem, index) => (
        <FaqItem {...faqItem} key={index} />
      ))}
    </div>
  )
}
